<template>
  <nav class="bottom-nav">
    <div class="bottom-nav__container">
      <ul class="bottom-nav__menu">
        <menu-component />
        <!-- <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
          <img src="@/assets/images/icons/Hamburguer2.svg" alt="hamburguer">
        </button> -->
        <div class="bottom-nav__info">
          <ul class="bottom-nav__info--list">
            <li class="bottom-nav__info--list-item">
              <router-link to="deporte"><img class="bottom-nav__icon" src="@/assets/images/icons/sports.svg"
                  alt="Sports-icons" />Deporte</router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="casino"><img class="bottom-nav__icon" src="@/assets/images/icons/casino.svg"
                  alt="Casino-icons" />Casino</router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="caballos"><img class="bottom-nav__icon" src="@/assets/images/icons/horses.svg"
                  alt="Racebook-icons" />caballos</router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="apuestas-en-vivo"><img class="bottom-nav__icon" src="@/assets/images/icons/live.svg"
                  alt="Racebook-icons" />apuestas en vivo</router-link>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  </nav>
</template>

<script>
import MenuComponent from "@/components/menu.vue";

export default {
  name: "HeaderComponent",
  components: {
    MenuComponent,
  },
  setup() { },
};
</script>
