import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/deporte',
    component: () => import('../pages/deporte.vue'),  
  },
  {
    path: '/casino',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/caballos',
    component: () => import('../pages/caballos.vue'),  
  },
  {
    path: '/apuestas-en-vivo',
    component: () => import('../pages/apuestas-en-vivo.vue'),  
  },
  {
    path: '/crea-tu-apuesta',
    component: () => import('../pages/crea-tu-apuesta.vue'),  
  },
  {
    path: '/reglas-de-la-casa',
    component: () => import('../pages/reglas-de-la-casa.vue'),  
  },
  {
    path: '/reglas-de-deporte',
    component: () => import('../pages/reglas-de-deporte.vue'),  
  },
  // {
  //   path: '/olvidaste-la-contrasena',
  //   component: () => import('../pages/olvidaste-la-contrasena.vue'),  
  // },
  {
    path: '/reglas-de-caballos',
    component: () => import('../pages/reglas-de-caballos.vue'),  
  },
  {
    path: '/reglas-parlay-del-mismo-equipo',
    component: () => import('../pages/reglas-parlay-del-mismo-equipo.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router