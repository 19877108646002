<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <span class="material-symbols-rounded header-content__hamburger">menu</span>
    </button>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <!-- <img class="hamburger-menu__logo" src="@/assets/images/logo.png" alt="logo"> -->
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span
          class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
        <router-link :to="route.route">{{route.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      isActive: false,
      routes: [
        {
          route: 'deporte',
          name: 'deporte'
        },
        {
          route: 'casino',
          name: 'casino'
        },
        {
          route: 'caballos',
          name: 'caballos'
        },
        {
          route: 'apuestas-en-vivo',
          name: 'apuestas en vivo'
        },
        {
          route: 'crea-tu-apuesta',
          name: 'crea tu apuesta'
        }
      ],
      ItemImg: [
        {
          image: require("@/assets/images/icons/sports.svg"),
          alt: "Lorem ipsum",
        },
        {
          image: require("@/assets/images/icons/live.svg"),
          alt: "Lorem ipsum",
        },
        {
          image: require("@/assets/images/icons/casino.svg"),
          alt: "Lorem ipsum",
        },
        {
          image: require("@/assets/images/icons/horses.svg"),
          alt: "Lorem ipsum",
        }
      ]
    };
  },
};
</script>
